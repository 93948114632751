import MainWrapper from "../../Layouts/MainWrapper";
import Banner from "./Banner/Banner";

import CalculateProfitRibbon from "../../Components/CalculateProfitRibbon/CalculateProfitRibbon";
import OurFeatures from "../../Components/Sections/OurFeatures/OurFeatures";
import HappyCustomers from "../../Components/Sections/HappyCustomers/HappyCustomers";
import WinningPotential from "../../Components/Sections/WinningPotential/WinningPotential";
import BottomSection from "../../Components/Sections/BottomSection/BottomSection";

import FaqSection from "../../Components/Sections/FaqSection/FaqSection";

import "./styles.scss";
import {SHOW_POTENTIAL_PROFIT} from "../../Constants/Values";
import React, {useEffect, useState} from "react";
import Loading from "../../Components/Loading";
import {fetchLayoutContent} from "../../Services/Blog/BlogService";
import envConfig from "../../Configs/env.config";

const Home = () => {
    const [loading, setLoading] = useState(false);
    const [homepageData, setHomepageData] = useState<any>({
        bannerData: {
            WelcomeTitle: "",
            FreeCredits: "",
            Section1Title: "",
            Section1Content: "",
        },
        featureData: {
            Section2Title: "",
            Section2Content: "",
            CupTitle: "",
            CupContent: "",
            RateTitle: "",
            RateContent: "",
            TimeTitle: "",
            TimeContent: "",
            ThunderBoltTitle: "",
            ThunderBoltContent: "",
        },
        testimonialSection: {
            "TestimonialTitle": "",
            "TestimonialContent": "",
            "testimonialsSection": null,
        },
        faqData: null
    });

    const fetchManifestHandler = async () => {
        const url = `${envConfig.REACT_APP_DIRECTUS_BASE_URL}items/manifest`
        try {
            setLoading(true);

            const response = await fetch(url, {
                headers: {
                    "Content-Type": "application/json",
                },
                cache: "no-cache",
            });
            if (!response.ok) {
                console.error(`Response status: ${response.status}`);
                setLoading(false);
                return;
            }

            const res = await response.json();

            const version = res?.data?.version || null;

            if (version) {
                const previousVersion = localStorage.getItem("version");

                if (previousVersion !== version && previousVersion) {
                    localStorage.clear();

                    const cache = await caches.open("layoutContents");
                    cache.delete("layoutContents").then(() => {
                        fetchHomepageHandler().then(() => {
                            window.location.reload()
                        })
                    }).then(() => {
                        localStorage.setItem("version", version);
                    }).catch((err) => {
                        console.error(err);
                    });

                    return;
                }

                localStorage.setItem("version", version);
                fetchHomepageHandler();
                setLoading(false);
            } else {
                fetchHomepageHandler();
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }

    }

    const fetchHomepageHandler = async () => {
        setLoading(true);

        await fetchLayoutContent().then((res: any) => {
            const metaTitle = res?.home_page_sections?.translations[0]?.meta_title;
            const metaDescription = res?.home_page_sections?.translations[0]?.meta_description;

            setHomepageData({
                bannerData: {
                    WelcomeTitle: res?.home_page_sections?.translations[0].WelcomeTitle,
                    FreeCredits: res?.home_page_sections?.translations[0].FreeCredits,
                    Section1Title: res?.home_page_sections?.translations[0].Section1Title,
                    Section1Content: res?.home_page_sections?.translations[0].Section1Content,
                },
                featureData: {
                    Section2Title: res?.home_page_sections?.translations[0].Section2Title,
                    Section2Content: res?.home_page_sections?.translations[0].Section2Content,
                    CupTitle: res?.home_page_sections?.translations[0].CupTitle,
                    CupContent: res?.home_page_sections?.translations[0].CupContent,
                    RateTitle: res?.home_page_sections?.translations[0].RateTitle,
                    RateContent: res?.home_page_sections?.translations[0].RateContent,
                    TimeTitle: res?.home_page_sections?.translations[0].TimeTitle,
                    TimeContent: res?.home_page_sections?.translations[0].TimeContent,
                    ThunderBoltTitle: res?.home_page_sections?.translations[0].ThunderBoltTitle,
                    ThunderBoltContent: res?.home_page_sections?.translations[0].ThunderBoltContent,
                },
                testimonialSection: {
                    "TestimonialTitle": res?.home_page_sections?.translations[0].TestimonialTitle,
                    "TestimonialContent": res?.home_page_sections?.translations[0].TestimonialContent,
                    "testimonialsSection": res?.testimonials || null,
                },
                faqData: res?.faqs || null
            });

            document.title = metaTitle || "";
            document.querySelector('meta[property="og:title"]')?.setAttribute("content", metaTitle || "");
            document.querySelector('meta[property="og:description"]')?.setAttribute("content", metaDescription || "");
            document.querySelector('meta[name="description"]')?.setAttribute("content", metaDescription || "");
            document.querySelector('meta[property="og:url"]')?.setAttribute("content", window.location.href);
            document.querySelector('meta[name="twitter:title"]')?.setAttribute("content", metaTitle || "");
            document.querySelector('meta[name="twitter:description"]')?.setAttribute("content", metaDescription || "");

            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchManifestHandler();
    }, []);

    return (
        <MainWrapper>

            <Banner bannerData={homepageData?.bannerData}/>

            {SHOW_POTENTIAL_PROFIT && <CalculateProfitRibbon/>}

            <OurFeatures featureData={homepageData?.featureData}/>

            <HappyCustomers testimonialSection={homepageData?.testimonialSection}/>

            <FaqSection faqData={homepageData?.faqData}/>

            <WinningPotential/>
            <BottomSection/>
            {loading && <Loading/>}

        </MainWrapper>
    )
}

export default Home;