import React, {useEffect, useState} from "react";
import {Spin} from "antd";
import {useNavigate, useParams} from "react-router-dom";

import MainWrapper from "../../Layouts/MainWrapper";

import useMatchMedia from "../../Hooks/useMatchMedia";
import {MOBILE_MAX_WIDTH_QUERY} from "../../Constants/Values";
import {getArticle, getArticles, searchArticles} from "../../Services/Blog/BlogService";
import BlogListNew from "../../Components/Blog/BlogList/BlogListNew";
import BlogDetailNew from "../../Components/Blog/BlogDetail/BlogDetailNew";


type PaginationProps = {
    totalItemCount: number,
    pageNumber: number,
    pageSize: number
}

const Blog = () => {
    const {match: isMobileDevice} = useMatchMedia(MOBILE_MAX_WIDTH_QUERY);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [articles, setArticles] = useState([]);
    const [recentArticles, setRecentArticles] = useState([]);
    const [popularArticles, setPopularArticles] = useState([]);
    const [article, setArticle] = useState<any>(null);
    const [searchKey, setSearchKey] = useState("")
    const [searchResults, setSearchResults] = useState([]);
    const [sortOrder, setSortOrder] = useState("-published_date");

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const searchParam = params.get('search');
    const searchValue = searchParam ? searchParam : "";
    const [paginationData, setPaginationData] = useState<PaginationProps>({
        totalItemCount: 0,
        pageNumber: 1,
        pageSize: 9
    });

    const {blogSlug} = useParams();

    const articleHandler = async () => {
        setLoading(true)

        await getArticles(paginationData.pageNumber, paginationData.pageSize, "en-US", sortOrder).then((res) => {
            setLoading(false)

            setArticles(res.articles)
            setPaginationData({
                ...paginationData,
                totalItemCount: res.slug_count[0]?.count?.slug || 0
            });

            setRecentArticles(res?.recent_articles);
            setPopularArticles(res?.popular_articles)

            return res;

        }).catch((err) => {
            setLoading(false)
            return err;
        })
    }

    const getArticleBySlug = async () => {
        setLoading(true)
        await getArticle(blogSlug).then((res) => {
            setArticle(res);

            setLoading(false)
        }).catch((err) => {
            console.log(err);
            setLoading(false)
        })
    }

    const searchArticlesHandler = async (keyword: string) => {
        setLoading(true)
        await searchArticles(keyword).then((res) => {
            setSearchResults(res.articles)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (blogSlug) {
            getArticleBySlug();
        }

        if (searchValue) {
            searchArticlesHandler(searchValue);
        }

        articleHandler();
    }, [blogSlug, searchValue, paginationData.pageNumber, sortOrder]);

    if (loading) return <div className="spinner-container">
        <Spin size="large"/>
    </div>;

    return (
        <MainWrapper isHomepage={false}>
            <div className="container mt-28">
                {(blogSlug && article) ? <BlogDetailNew article={article}/> : <BlogListNew
                    searchKey={searchKey}
                    setSearchKey={setSearchKey}
                    paginationData={paginationData}
                    setPaginationData={setPaginationData}
                    setSortOrder={setSortOrder}
                    sortOrder={sortOrder}
                    articles={
                        searchValue ? searchResults :
                            articles
                    }/>}

            </div>
        </MainWrapper>
    );
}

export default Blog;