import {Suspense} from 'react';
import {Toaster} from 'react-hot-toast';
import {BrowserRouter} from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import './App.scss';
import AppProvider from './Context/AppContext';
import Router from './Routes/Routes';
import {ConfigProvider} from 'antd';
import './i18n/i18n';
import {LoginPopUpProvider} from "./Context/LoginContext";

function App() {
    return (
        <BrowserRouter>
            <AppProvider>
                {/* <ErrorHandler>
			</ErrorHandler> */}
                <LoginPopUpProvider>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: '#20A0E1',
                                colorBgContainer: '#ffffff',
                            },
                            components: {
                                Switch: {
                                    handleBg: '#8EE559',
                                },
                            }
                        }
                        }
                    >
                        <Suspense fallback={'...'}>
                            <Toaster
                                toastOptions={{
                                    style: {
                                        fontFamily: 'Arial, sans-serif',
                                        fontSize: '16px',
                                    },
                                }}
                            />
                            <Router/>
                        </Suspense>
                    </ConfigProvider>
                </LoginPopUpProvider>
            </AppProvider>
        </BrowserRouter>
    );
}

export default App;
