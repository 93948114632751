import {Link} from "react-router-dom";

import {ReactComponent as GoogleIcon} from "../../../Assests/Icons/google-play.svg";
import {ReactComponent as AppleIcon} from "../../../Assests/Icons/apple-store.svg";
import {TELEGRAM_URLS} from "../../../Constants/Values";

const {
    REACT_APP_ENV
} = (window as any).__env__;

const TelegramStep = () => {

    const telegramLinkHandler = () => {
        if (REACT_APP_ENV === "dev") {
            window.open(TELEGRAM_URLS.dev, "_blank");
        } else if (REACT_APP_ENV === "staging") {
            window.open(TELEGRAM_URLS.staging, "_blank");
        } else if (REACT_APP_ENV === "qa") {
            window.open(TELEGRAM_URLS.qa, "_blank");
        } else {
            window.open(TELEGRAM_URLS.prod, "_blank");
        }
    }

    return (
        <div className="px-8 flex items-center flex-col justify-start">
            <div className="bg-lemon-green w-14 h-14 flex items-center justify-center rounded-full">
                <i className="bi bi-check text-5xl text-white"></i>
            </div>

            <span className="font-sans text-sm font-normal text-gray-darker mb-2 mt-1">
                                Success!
                            </span>

            <span className="font-sans text-sm font-normal text-gray-darker">
                                Our tips are sent via Telegram click below
                            </span>

            <button
                className="bg-blue text-white font-sans text-base font-normal rounded-xl px-8 py-3 rounded-full mt-2"
                onClick={() => telegramLinkHandler()}
            >Start receiving tips
            </button>

            <span className="font-sans text-sm font-normal text-gray-darker text-center mt-1">
                                Once Telegram registration is completed click refresh to access the portal <Link
                to={""}
                className="text-blue"
                onClick={() => {
                    window.location.reload();
                }}
            >Refresh</Link>
                            </span>

            <div className="mt-4">
                <div className="flex items-center gap-2">
                    <GoogleIcon
                        className="cursor-pointer"
                        onClick={() => {
                            window.location.href = "https://play.google.com/store/apps/details?id=org.telegram.messenger";
                        }}
                    />
                    <AppleIcon
                        className="cursor-pointer"
                        onClick={() => {
                            window.location.href = "https://apps.apple.com/us/app/telegram-messenger/id686449807";
                        }}
                    />
                </div>
            </div>

            <span className="font-sans text-sm font-normal text-gray-darker text-center mt-2">
                                    Don't have Telegram? <br/> Download it here and complete registration using the email we sent you.
                                </span>
        </div>
    )
}

export default TelegramStep;