import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {Tooltip} from "react-tooltip";
import {SubmitHandler, useForm} from "react-hook-form";
import {Link} from "react-router-dom";

import {ReactComponent as TopShape} from "../../Assests/Icons/shapes/PopUpShapeTop.svg";
import {ReactComponent as BottomShape} from "../../Assests/Icons/shapes/PopUpShapeBottom.svg";
import {ReactComponent as CloseIcon} from "../../Assests/Icons/close.svg";
import {ReactComponent as InfoIcon} from "../../Assests/Icons/info.svg";
import {ReactComponent as LineIcon} from "../../Assests/Icons/line.svg";

import AuthService from "../../Services/Auth/AuthService";

import Toast from "../../Utils/ToastHandler";
import {MOBILE_MAX_WIDTH_QUERY, TOAST_BOTTOM_CENTER, TOAST_DURATION} from "../../Constants/Values";

import "./Styles.scss"
import {gtagEventHandler} from "../../Utils/gtagEventHandler";
import {hidePhoneNumber} from "../../Utils/hidePhoneNumber";
import useMatchMedia from "../../Hooks/useMatchMedia";

type Props = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    phoneNumber: string;
    loading: boolean;
    nextPageHandler: React.Dispatch<React.SetStateAction<number>>;
    loadingHandler: (arg1: boolean) => void;
    phoneHandler: (arg1: string, arg2: number) => void;
    otpMethod: string;
}

type FormData = {
    otp: string;
};

const OtpPopup = ({
                      isOpen,
                      setIsOpen,
                      nextPageHandler,
                      phoneHandler,
                      phoneNumber,
                      loadingHandler,
                      otpMethod,
                      loading
                  }: Props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {match: isMobileDevice} = useMatchMedia(MOBILE_MAX_WIDTH_QUERY);

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors, isValid, isDirty},
    } = useForm<FormData>({
        mode: "onChange",
    });

    const submitForm = (otp: string) => {
        loadingHandler(true);
        AuthService.verifyOTP(otp, phoneNumber)
            .then(() => {
                loadingHandler(false);
                nextPageHandler(1);

                gtagEventHandler({
                    event_category: "OTP - Phone Verification Success",
                    value: "otp-verified-" + otpMethod + " - " + hidePhoneNumber(phoneNumber),
                    event_label: 'Phone Verification - OTP Verified',
                }, 'phone_verification_otp_verified');

                setIsOpen(false);
                navigate("/dashboard");
            })
            .catch((err) => {
                loadingHandler(false);
                const error = err.response.data.error;

                gtagEventHandler({
                    event_category: "OTP - Phone Verification Error",
                    value: "otp-error-" + error + " - " + hidePhoneNumber(phoneNumber),
                    event_label: 'Phone Verification - OTP Error',
                }, 'phone_verification_otp_error');

                if (error === "invalid_grant" || error === "invalid_otp") {
                    setError("otp", {
                        type: "manual",
                        message: t("invalid_grant"),
                    });
                } else {
                    Toast.error(
                        error ? t(error) : t("generic-error"),
                        TOAST_DURATION,
                        TOAST_BOTTOM_CENTER
                    );
                }
            });
    };

    return (
        <section
            id="otp-popup"
            className={[
                "popup",
                "panel panel-modal",
                isOpen ? "active" : "",
            ].join(" ")}
        >
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="otp-modal-inner">
                    <div
                        className="panel-modal-inner shadow"
                    >
                        <div className="background-image">
                            {/*<TopShape className="background-image-top"/>*/}
                            {/*<BottomShape className="background-image-bottom"/>*/}
                        </div>

                        {
                            isMobileDevice ? (
                                <div className='flex items-center justify-center mt-[-20px] mb-2'>
                                    <LineIcon
                                        onClick={() => {
                                            nextPageHandler(0)
                                            setIsOpen(false)
                                        }}
                                    />
                                </div>
                            ): (
                                <div className="close-icon-button">
                                    <CloseIcon onClick={() => {
                                        nextPageHandler(0)
                                        setIsOpen(false)
                                    }}
                                               className="cursor-pointer text-gray-darker"
                                    />
                                </div>
                            )
                        }

                        <div className="popup-content xl:text-center">

                            <p className="font-sans text-base font-normal text-gray-darker">To continue, complete this verification step.</p>
                            <p className="font-sans text-base font-normal text-gray-darker">
                                We’ve sent a One Time Password (OTP) to the phone {phoneNumber} via {otpMethod === "Telegram" ? otpMethod : otpMethod.toUpperCase()}.
                            </p>
                            <p
                                className="font-sans text-base font-normal text-gray-darker">Please enter it below.</p>

                            <h3 id="otp-title"
                                className="position-relative d-flex align-items-center justify-content-center font-sans otp-input-title"
                            >Enter
                                OTP <InfoIcon
                                    data-tooltip-html={otpMethod === "Telegram" ? t("Otp-Guide-Tooltip") : t("Otp-Guide-Tooltip-Sms")}
                                    data-tooltip-id="otpTooltip"
                                    className="ms-2 text-white"
                                />
                                <Tooltip id="otpTooltip" className=""/>
                            </h3>

                            <div className="popup-otp-input-container">
                                <input
                                    id="otp-input"
                                    type="number"
                                    max={6}
                                    disabled={loading}
                                    className="form-control border-0 rounded-md"
                                    placeholder="000000"
                                    {...register("otp", {
                                        pattern: {
                                            value: /^[0-9]*$/,
                                            message: t("Step-Two-OTP-Only-Numbers"),
                                        },
                                        required: {
                                            value: true,
                                            message: t("Step-Two-OTP-Required"),
                                        },
                                        minLength: {
                                            value: 6,
                                            message: t("Step-Two-OTP-Must-Be-6-Digits"),
                                        },
                                        maxLength: {
                                            value: 6,
                                            message: t("Step-Two-OTP-Cant-Be-More-Than-6-Digits"),
                                        },

                                        onChange: (e) => {
                                            if (e.target.value.length === 6) {
                                                submitForm(e.target.value)
                                            }
                                        }
                                    })}
                                    maxLength={6}
                                    required={true}
                                />
                            </div>
                            <div className="text-error" id="otp-error-message">{errors.otp?.message}</div>

                            <div className="text-center">
                                <Link to=""
                                      id="resend-otp"
                                      className="position-relative"
                                      onClick={(e) => {
                                          e.preventDefault();

                                          gtagEventHandler({
                                              event_category: "OTP - Resend OTP",
                                              value: "resend-otp-" + otpMethod + " - " + hidePhoneNumber(phoneNumber),
                                              event_label: 'Phone Verification - Resend OTP',
                                          }, 'phone_verification_resend_otp');

                                          phoneHandler(phoneNumber, 1);
                                      }}>
                                    {otpMethod === "Telegram" ? t("Resend-OTP") : t("Resend-OTP-Sms")}
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>

                <div
                    className="panel-overlay"
                ></div>
            </form>
        </section>
    )
}

export default OtpPopup